import React from "react";
import styles from "./Home.module.css";
import BlueFudge1 from "../../assets/images/fudge1.svg";
import BlueFudge2 from "../../assets/images/fudge2.svg";
import { BannerCard } from "../../components";
import GridInfo from "../../components/InfoTable/InfoGrid";
import InfoCard from "../../components/InfoTable/InfoCard";

import { connect } from "react-redux";
import { loading } from "../../redux/actions";

const Home = (props) => {
  // React.useEffect(() => {
  //   props.loading(false);
  // }, [props]);

  return (
    <div className={styles.container}>
      <img className={styles.blueFudge1} src={BlueFudge1} alt="" />
      <img className={styles.blueFudge2} src={BlueFudge2} alt="" />

      <BannerCard />
      <GridInfo
        cards={[
          [
            <InfoCard
              title={"Project Information"}
              info={[
                { key: "Current Stage", value: `FCFS` },
                { key: "Whitelist", value: "TBA" },
                { key: "Presale Cap", value: "175 ETH" },
                {
                  key: "Private Sale Price",
                  value: "1 FTML = 0.003 USD",
                },
                {
                  key: "Public Sale Price",
                  value: "1 FTML = 0.005 USD",
                },
                { key: "Access Type", value: "Whitelist" },
              ]}
            />,
            // <InfoCard
            //   title={"Listing"}
            //   info={[
            //     { key: "Listing Platform", value: "TBA" },
            //     { key: "Listing Date", value: "TBA" },
            //     { key: "Listing Price", value: "1 FTML = 0.008 USD" },
            //   ]}
            // />,

            <InfoCard
              title={"Token Information"}
              info={[
                { key: "Name", value: "FTMLaunch" },
                { key: "Token Symbol", value: "FTML" },
                { key: "Total Supply", value: "1,000,000,000 FTML" },
                { key: "Decimals", value: "18" },
                { key: "Tokens for Sale", value: "150,000,000 FTML" },
              ]}
            />,

            // <InfoCard
            //   title={"Presale Schedule"}
            //   info={[
            //     { key: "Presale Start", value: "Dec 7th, 15:00 UTC" },
            //     { key: "Presale End", value: "Until Cap Reached" },
            //     { key: "Type", value: "Whitelist" },
            //   ]}
            // />,
          ],
        ]}
      />
    </div>
  );
};

export default connect(null, { loading })(Home);
