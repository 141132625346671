import web3 from "../web3";
import { VestingInterface } from '../interfaces';
import { address } from '../address';

const contract = new web3.eth.Contract(VestingInterface, address.vesting);

export const claim = async (address, amount) => {
  await contract.methods
    .claim(address, web3.utils.toWei(amount))
    .send({ from: address });
}

export const getFTMLTotalToReceive = async (address) => {
  return web3.utils.fromWei(await contract.methods
    .investorsFTML(address)
    .call({ from: address }));
}

export const getFTMLTotalToBeVested = async (address) => {
  return web3.utils.fromWei(await contract.methods
    .getFTMLTotalToBeVested(address)
    .call({ from: address }));
}

export const claimableFTMLCurrentCycle = async (address) => {
  return web3.utils.fromWei(await contract.methods
    .claimableFTMLCurrentCycle(address)
    .call({ from: address }));
}


