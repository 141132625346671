const initialState = {
  activeLoaderType1: 0,
  activeLoaderType1Type: null,
};

const Loader = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING":
      let lc = state.activeLoaderType1;
      if (action.payload.loading) {
        lc++;
      } else {
        lc = lc === 0 ? 0 : lc - 1;
      }
      return {
        ...state,
        activeLoaderType1: lc,
        activeLoaderType1Type: action.payload.type ?? null,
      };
    default:
      return state;
  }
};

export default Loader;
