import chain from "./chain";

const Config = env => {
    const getConfig = (url, chainId) => {
        return { 
            provider: url,
            chainId,
            chain: chain
        };
    }
    let config;
    switch (env){
        case 'development':
            config = getConfig(
                `https://rpc.testnet.fantom.network`,
                4002
            );
            break;
        case 'production':
            config = getConfig(
                `https://rpc.ftm.tools`,
                250
            );
            break;
        default: 
            config = getConfig(
                `https://rpc.testnet.fantom.network`,
                4002
            );
    }
    return config;
}

export default Config;